<template>
  <div id="skill-overview">
    <template v-for="hardskill in hardskills" v-bind:key="hardskill.id">
        <div class="skill-box">
            <img class="skill-icon" :src="getImageSource(hardskill.icon.url)" />
            <h4>{{hardskill.title}}</h4>
        </div>
    </template>
  </div>
</template>

<script>

export default {
    props: {
        hardskills: { default: [] }
    },
    setup(props) {
        const getImageSource = (imgSrc) => {
          if(!imgSrc.includes("https://"))
            return require('/src/assets/' + imgSrc);
          else
            return imgSrc;
        }

        return { props, getImageSource }
    },
}
</script>

<style lang="scss">
    #skill-overview {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .skill-icon {
        max-width: 100px;
        max-height: 100px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .skill-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;

        h4 {
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }
</style>