<template>
    <div class="author-and-release">
      <div class="author-info">
        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
        <p>{{ creatorname }}</p>
      </div>
      <div class="release-info">
        <p>{{ getReleaseDate(releaseDate) }}</p>
        <i class="fa fa-clock-o" aria-hidden="true"></i>
      </div>
    </div>
</template>

<script>
export default {
    props: {
        colorStyle: { default: 0 },
        creatorname: { default: '' },
        releaseDate: { default: '' }
    },
    setup(props) {

        const getReleaseDate = (date) => { 
        const publishDate = new Date(date);
        return publishDate.getUTCDate() + "." + publishDate.getMonth() + "." + publishDate.getFullYear();
        }

        return { props, getReleaseDate }
    },
}
</script>

<style lang="scss" scoped>
    .author-and-release {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 3%;
    }

      .author-info {
    display: flex;
    align-items: center;

    /deep/ p {
      margin-left: 5px;
    }
  }

  p {
    margin: 2% 0;
  }

  .release-info {
    display: flex;
    align-items: center;

    /deep/ p {
      margin-right: 5px;
    }
  }

  .text {
    color: var(--text-color);
  }

</style>