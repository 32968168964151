<template>
  <Techblogheader :back="true" />
  <div class="article" v-if="currentPost">
        <div class="article-inner">
        <img id="thumbnail" :src="currentPost.thumbnail.url" />
        <AuthorAndReleaseInfo :creatorname="currentPost.author.name" :releaseDate="currentPost.published_at"/>
        <h2 v-if="selectedLanguageEN">{{currentPost.title}}</h2>
        <h2 v-else>{{currentPost.title_DE}}</h2>
        <p v-if="selectedLanguageEN" id="teaser">{{currentPost.teaser}}</p>
        <p v-else id="teaser">{{currentPost.teaser_DE}}</p>
        <div v-if="currentPost.downloadables.length > 0" class="download-section">
            <h3>Downloads:</h3>
            <div v-for="download in currentPost.downloadables" v-bind:key="download.id" class="downloads">
                <a :href="download.url" target="_blank">{{download.name}}</a>
            </div>
        </div>
        <h3>Skills:</h3>
        <SkillOverview v-if="currentPost.hardskills && currentPost.hardskills.length > 0" :hardskills="currentPost.hardskills" />
        <p v-else>Keine Skills zu dem Artikel/No Skills associated.</p>
        <h3 v-if="selectedLanguageEN && currentPost.paragraph_one_header_EN">{{currentPost.paragraph_one_header_EN}}</h3>
        <h3 v-else>{{currentPost.paragraph_one_header_DE}}</h3>
        <p v-if="selectedLanguageEN">{{currentPost.paragraph_one_EN}}</p>
        <p v-else>{{currentPost.paragraph_one_DE}}</p>
        <img class="paragraph-image" v-if="currentPost.media.length > 0" :src="currentPost.media[0].url" />
        <h3 v-if="selectedLanguageEN && currentPost.paragraph_two_header_EN">{{currentPost.paragraph_two_header_EN}}</h3>
        <h3 v-if="!selectedLanguageEN && currentPost.paragraph_two_header_DE">{{currentPost.paragraph_two_header_DE}}</h3>
        <p v-if="selectedLanguageEN">{{currentPost.paragraph_two_EN}}</p>
        <p v-else>{{currentPost.paragraph_two_DE}}</p>
        <img class="paragraph-image" v-if="currentPost.media.length > 1" :src="currentPost.media[1].url" />
        <h3 v-if="selectedLanguageEN && currentPost.paragraph_three_header_EN">{{currentPost.paragraph_three_header_EN}}</h3>
        <h3 v-if="!selectedLanguageEN && currentPost.paragraph_three_header_DE">{{currentPost.paragraph_three_header_DE}}</h3>
        <p v-if="selectedLanguageEN">{{currentPost.paragraph_three_EN}}</p>
        <p v-else>{{currentPost.paragraph_three_DE}}</p>
        <div v-if="currentPost.media.length > 2">
            <img v-for="element in mediaExceptFirstTwo" v-bind:key="element.id" class="paragraph-image" :src="element.url" />
        </div>
        
    </div>
  </div>
  <Footer :isPortfolio="false" />
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core'
import * as http from '@/http';
import store from '@/store';
import Footer from '@/components/semantic/Footer.vue';
import Techblogheader from '@/components/semantic/Techblogheader.vue';
import AuthorAndReleaseInfo from '@/components/elements/AuthorAndReleaseInfo.vue';
import SkillOverview from '@/components/semantic/SkillOverview.vue';
import { Language } from '@/interfaces/Language';

export default {
    components: {
        Footer,
        Techblogheader,
        AuthorAndReleaseInfo,
        SkillOverview
    },
    setup() {

        const currentPost = ref(null);

        const selectedLanguageEN = computed(() => store.state.selectedLanguage === Language.EN);

        onMounted(async () => {
            await getPostById();
        })

        const getPostById = async () => {
            currentPost.value = await http.getPostById(store.state.articleId);
            console.log("Current Post: ", currentPost.value);
        }

        const mediaExceptFirstTwo = computed(() => currentPost.value.media.slice(2, currentPost.value.media.length));

        return { currentPost, selectedLanguageEN, mediaExceptFirstTwo };
    },
}
</script>
<style lang="scss" scoped>
@import '@/main.scss';

.article {
    padding: 30% 7%;
    background-color: var(--background-light);
}

.download-section {
    margin: 5% 0;

    a {
        background: var(--background-light);
        border: 2px solid;
        border-color: var(--highlight-color);
        border-radius: 5px;
        padding: 5px 10px;
        color: var(--text-light);
        font-weight: bold;
        filter: drop-shadow(rgba(0, 0, 0, 0.25) 4px 4px 4px);
        text-decoration: none;
    }
}

.downloads {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
}

h2 {
    text-align: left;
    color: var(--text-color);
}

p {
    text-align: left;
    color: var(--text-color);
    white-space: pre-wrap;
}

#teaser {
    font-weight: bold;
}

#thumbnail {
    max-width: 100%;
}

.paragraph-image {
    max-height: 500px;
    max-width: 100%;
    width: auto;
    margin: 10px;
}

p {
    margin-bottom: 10px;
}

  @media screen and (min-width: $break-md) { 

    #thumbnail {
        width: 100%;
    }

    .article {
        padding: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .article-inner {
        max-width: 85ch;
    }
  }

</style>
